
html {
	font-size: 14px;
}

.header-banner {
	background-image: url("/img/header-background.png");
	background-repeat: repeat-x;
}

.header-menu {
	background: #313c75;
	border-color: white;
	border-top: 1px solid;
	border-bottom: 1px solid;
	color: white;

	.nav-item {
		border-color: white;
		border-right: 1px solid;

		&:first-child {
			border-left: 1px solid;
		}
	}

	.nav-link {
		color: white;

		&:hover {
			color: white;
			background: rgba(255, 255, 255, 0.3);
		}
	}
}

main {
	margin-top: 33px;
	margin-bottom: 33px;
}

.card-header {
	overflow-x: auto;
}
